/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import ReactPlayer from "react-player"
import {
  AspectRatio,
  Box,
  Container,
  Flex,
  Heading,
  Themed,
  jsx,
} from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

import { getStrapiCorrectImageSrc } from "helpers/index"
import BackgroundSVG from "images/icons/background-light.svg"

import AguilaButton from "components/AguilaButton"
import Award from "components/Award"
import BgImage from "components/BgImage"
import HoverImage from "components/HoverImage"
import LineAnimation from "components/common/LineAnimation"
import LinksBlock from "components/LinksBlock"
import MdContent from "components/common/MdContent"
import SectionHeading from "components/SectionHeading"

interface AboutUsBlockMapperProps {
  block: any
}

const AboutUsBlockMapper = ({ block }: AboutUsBlockMapperProps) => {
  switch (block.strapi_component) {
    case "about-us-components.mission-section":
      const linkListData = block.link_list.map((item: any) => ({
        text: item.link_text,
        url: item.link_url,
      }))

      return (
        <Box as="section" sx={{ position: "relative" }}>
          <LineAnimation
            sx={{ height: ["50%", null, "78%"], top: ["20%", null, 0]}}
          />
          <BackgroundSVG
            sx={{
              position: "absolute",
              zIndex: -1,
              transformOrigin: "center",
              top: "-60%",
              right: 0,
              width: "auto",
              height: "160%",
              transform: "translate(30%, 0)",
              "& > g": {
                opacity: 0.2,
              },
              "& *": {
                fill: "#d6d1c4cf",
              },
            }}
          />
          <SectionHeading>{block.title}</SectionHeading>
          <Container>
            <Heading
              variant="title"
              sx={{
                textAlign: "center",
                maxWidth: "790px",
                mx: "auto",
                lineHeight: "1.1",
                mb: [12, null, 32],
              }}
            >
              {block.large_bold_text}
            </Heading>
            <Box sx={{ variant: "layout.wide" }}>
              <LinksBlock
                sx={{ mb: 48 }}
                heading="See how we can help"
                links={linkListData}
              />
              <Box>
                <Heading variant="title" sx={{ mb: 5 }}>
                  {block.subtitle}
                </Heading>
                <MdContent source={block.content} sx={{ mb: 8 }} />
                <AguilaButton
                  url={block.link_button.link_button_url}
                  text={block.link_button.link_button_text}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      )
    case "about-us-components.image-row":
      const images = block.three_images.map((item: any) => ({
        src: getStrapiCorrectImageSrc(item.image.url),
        alt: item.image.alternativeText as string,
      }))

      return (
        <Box as="section" sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: ["25%", null, null, "45%"],
              left: ["50%", null, null, 0],
              right: ["unset", null, null, 0],
              width: ["2px", null, null, "100%"],
              height: ["60%", null, null, "2px"],
              backgroundColor: "text",
              content: "''",
              zIndex: -1,
            }}
          />
          <Container
            sx={{ maxWidth: "1370px", px: [4, null, null, 0], mx: "auto" }}
          >
            <Flex sx={{ flexDirection: ["column", null, null, "row"] }}>
              {images.map((img: any, index: number) => (
                <HoverImage
                  key={index}
                  normalImage={img.src}
                  imageAlt={img.alt}
                />
              ))}
            </Flex>
          </Container>
        </Box>
      )
    case "about-us-components.content-with-bullet-images":
      return (
        <Box as="section">
          <Flex
            sx={{
              maxHeight: "556px",
              alignItems: "flex-end",
              maxWidth: "560px",
              justifyContent: "space-between",
              mx: "auto",
              position: "relative",
              zIndex: 2,
            }}
          >
            <StaticImage
              src="../../images/rimfire-2x.png"
              alt="rimfire bullet"
              width={83}
              sx={{ mx: 6 }}
            />
            <StaticImage
              src="../../images/handgun-2x.png"
              alt="handgun bullet"
              width={91}
              sx={{ top: ["20px", null, "30px"], mx: 6 }}
            />
            <StaticImage
              src="../../images/rifle-2x.png"
              alt="rifle bullet"
              width={72}
              sx={{ top: ["35px", null, "50px"], mx: 6 }}
            />
            <StaticImage
              src="../../images/shotshell-2x.png"
              alt="shotshell bullet"
              width={130}
              sx={{ top: ["32px", null, "48px"], mx: 6 }}
            />
          </Flex>
          <BgImage
            normalImage={getStrapiCorrectImageSrc(block.background_image.url)}
            sx={{ mt: -56, position: "relative", zIndex: 0 }}
          >
            <Container
              variant="layout.wide"
              sx={{
                color: "textWhite",
                pt: 48,
                pb: 28,
                position: "relative",
                zIndex: 3,
              }}
            >
              <Box sx={{ maxWidth: "410px" }}>
                <Heading>Reliable and dependable, shot after shot</Heading>

                <MdContent
                  source={block.content}
                  sx={{
                    mb: 8,
                  }}
                ></MdContent>

                <AguilaButton
                  url={block.link_button.link_button_url}
                  text={block.link_button.link_button_text}
                  sx={{ color: "textWhite" }}
                />
              </Box>
            </Container>
          </BgImage>
        </Box>
      )
    case "about-us-components.innovations-section":
      return (
        <Box as="section" sx={{ position: "relative", my: [12, null, 28] }}>
          <LineAnimation
            sx={{
              height: ["35%", null, "55%"],
              top: ["5%", null, 0],
              zIndex: -1,
            }}
          />
          <Box sx={{ position: "relative", zIndex: 1 }}>
            <SectionHeading>{block.title}</SectionHeading>
            <Container sx={{ mb: 56 }}>
              <Box sx={{ maxWidth: "600px" }}>
                <Themed.ul>
                  {block.list_items.map((item: any, index: number) => (
                    <li sx={{ mb: 8 }} key={index}>
                      {item.list_item_text}
                    </li>
                  ))}
                </Themed.ul>
              </Box>
            </Container>
            <Container sx={{ variant: "layout.wide" }}>
              <Box sx={{ maxWidth: "600px", mb: [8, null, 16] }}>
                <Heading as="h3" variant="title">
                  {block.subtitle}
                </Heading>

                <MdContent source={block.content} />
              </Box>

              <Flex
                sx={{
                  width: "100%",
                  justifyContent: ["center", null, null, "space-between"],
                  gap: [2, null, 6],
                  flexWrap: "wrap",
                }}
              >
                {block.awards.map((award: any, index: number) => (
                  <Award
                    key={index}
                    awardTitle={award.title}
                    awardDates={award.years}
                    awardInfoLine1={award.line_1}
                    awardInfoLine2={award.line_2}
                  />
                ))}
              </Flex>
            </Container>
          </Box>
        </Box>
      )
    case "blog-components.video":
      return (
        <Container
          sx={{
            my: 12,
            position: "relative",
          }}
        >
          <AspectRatio ratio={16 / 9}>
            <ReactPlayer
              className="react-player"
              url={block.URL}
              width="100%"
              height="100%"
              sx={{
                left: 0,
                position: "absolute",
                top: 0,
              }}
            />
          </AspectRatio>
        </Container>
      )
    default:
      return <></>
  }
}

export default AboutUsBlockMapper
