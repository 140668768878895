/** @jsx jsx */
import { Flex, Heading, Text, jsx } from "theme-ui"

import AwardsBranch from "images/icons/awards-branch.svg"

interface AwardProps {
  //enables sx prop to be passed down from parent
  className?: string
  awardTitle: string
  /*
   * make sure to add dates with lines separating (2015 | 2016 ...)
   */
  awardDates: string
  awardInfoLine1: string
  awardInfoLine2: string
}

const Award = ({
  className,
  awardTitle,
  awardDates,
  awardInfoLine1,
  awardInfoLine2,
}: AwardProps) => {
  return (
    <Flex
      as="article"
      sx={{
        position: "relative",
        maxWidth: "261px",
        width: "100%",
      }}
      className={className}
    >
      <Flex
        sx={{
          position: "relative",
          zIndex: 0,
          justifyContent: "space-between",
          minWidth: "100%",
        }}
      >
        <AwardsBranch
          sx={{ transform: ["scale(.8, .8)", null, "scale(1, 1)"] }}
        />
        <AwardsBranch
          sx={{ transform: ["scale(-.8, .8)", null, "scale(-1, 1)"] }}
        />
      </Flex>
      <Flex
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          top: 0,
          left: 0,
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 9,
          textAlign: "center",
        }}
      >
        <Heading as="h1" variant="title">
          {awardTitle}
        </Heading>
        <Heading as="h2" variant="bodyTitle" sx={{ mb: 2 }}>
          {awardDates}
        </Heading>
        <Text sx={{ textTransform: "uppercase", lineHeight: "1.1" }}>
          {awardInfoLine1}
          <br />
          {awardInfoLine2}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Award
