/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { createRef } from "react"
import { motion, useMotionValue, useTransform, useSpring } from "framer-motion"

interface HoverImageProps {
  //enables sx prop to be passed down from parent
  className?: string
  image?: IGatsbyImageData
  normalImage?: string
  imageAlt: string
}

const HoverImage = ({
  className,
  image,
  normalImage,
  imageAlt,
}: HoverImageProps) => {
  const containerRef = createRef<HTMLDivElement>()
  const x = useMotionValue(200)
  const y = useMotionValue(200)

  const translateX = useSpring(useTransform(x, [0, 400], [20, -10]), {
    damping: 30,
    stiffness: 200,
  })
  const translateY = useSpring(useTransform(y, [0, 400], [20, -10]), {
    damping: 30,
    stiffness: 200,
  })

  const fgTranslateX = useSpring(
    useTransform(translateX, latest => latest * 2),
    { damping: 30, stiffness: 200 }
  )
  const fgTranslateY = useSpring(
    useTransform(translateY, latest => latest * 2),
    { damping: 30, stiffness: 200 }
  )

  function handleMouse(event: MouseEvent) {
    const rect = event.currentTarget.getBoundingClientRect()

    x.set(event.clientX - rect.left)
    y.set(event.clientY - rect.top)
  }

  const AnimatedBox = motion(Box)
  const ImageBox = motion(Box)
  const DropshadowBox = motion(Box)

  return (
    <AnimatedBox
      className={className}
      sx={{
        padding: "50px 0",
        mx: [0, null, null, 8],
        width: ["80%", null, null, "100%"],
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-of-type(even)": {
          marginTop: [0, null, null, -10],
          alignSelf: ["self-end", null, null, "flex-start"],
        },
      }}
      ref={containerRef}
      onMouseMove={handleMouse}
      // onMouseMove={(event) => mouseX.set(event.nativeEvent.x)}
      onMouseLeave={() => {
        x.set(200)
        y.set(200)
      }}
      // onMouseOut={()=> {x.destroy()
      //     y.destroy()}}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: ["70%", null, null, "381px"],
          height: [
            "clamp(150px, 40vw, 320px)",
            null,
            null,
            "clamp(120px, 20vw, 286px)",
          ],
        }}
      >
        <ImageBox
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            zIndex: 0,
          }}
          style={{ x: fgTranslateX, y: fgTranslateY }}
        >
          {image && !normalImage && (
            <GatsbyImage
              image={image}
              alt={imageAlt}
              sx={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          )}
          {!image && normalImage && (
            <img
              src={normalImage}
              alt={imageAlt}
              sx={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          )}
        </ImageBox>
        <DropshadowBox
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 5,
            left: 5,
            backgroundColor: "primary",
            zIndex: -1,
          }}
          style={{ x: translateX, y: translateY }}
        />
      </Box>
    </AnimatedBox>
  )
}

export default HoverImage
