/** @jsxRuntime classic */
/** @jsx jsx */
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql, PageProps } from "gatsby"
import { jsx } from "theme-ui"

import type { AboutPageQuery } from "../../graphql-types"

import AboutUsBlockMapper from "components/block-mappers/AboutUsBlockMapper"
import Layout from "components/Layout"
import PageHero from "components/PageHero"
import ReadyToBuyCTA from "components/ReadyToBuyCTA"

export const query = graphql`
  query AboutPage {
    strapiAbout {
      blocks
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Hero {
        hero_links {
          link_button_url
          link_button_text
        }
        title
        content
        has_lines
        has_locator
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_position
        subtitle
      }
    }
  }
`

interface AboutPageProps extends PageProps {
  data: AboutPageQuery
}

const AboutPage = ({ data, ...props }: AboutPageProps) => {
  return (
    <Layout seo={data.strapiAbout?.seo} location={props.location}>
      <PageHero
        title={data.strapiAbout?.Hero?.title as string}
        subtitle={data.strapiAbout?.Hero?.subtitle as string}
        content={data.strapiAbout?.Hero?.content as string}
        hasLines={data.strapiAbout?.Hero?.has_lines as boolean}
        hasLocatorCTA={data.strapiAbout?.Hero?.has_locator as boolean}
        imagePosition={data.strapiAbout?.Hero?.image_position as string}
        image={
          data.strapiAbout?.Hero?.image?.localFile?.childImageSharp
            ?.gatsbyImageData as IGatsbyImageData
        }
        imageAlt={data.strapiAbout?.Hero?.image?.alternativeText}
        buttonOneUrl={
          data.strapiAbout?.Hero?.hero_links?.[0]?.link_button_url as string
        }
        buttonOneText={
          data.strapiAbout?.Hero?.hero_links?.[0]?.link_button_text as string
        }
        buttonTwoUrl={
          data.strapiAbout?.Hero?.hero_links?.[1]?.link_button_url as string
        }
        buttonTwoText={
          data.strapiAbout?.Hero?.hero_links?.[1]?.link_button_text as string
        }
      />
      {data.strapiAbout?.blocks.map((block: any, index: number) => (
        <AboutUsBlockMapper key={index} block={block} />
      ))}

      <ReadyToBuyCTA />
    </Layout>
  )
}

export default AboutPage
