/** @jsx jsx */
import { Box, Heading, jsx } from "theme-ui"

import AguilaButton from "components/AguilaButton"

export interface IMyLink {
  url: string
  text: string
  rel?: string
}

interface LinksBlockProps {
  //enables sx prop to be passed down from parent
  className?: string
  heading: string
  links: IMyLink[]
}

const LinksBlock = ({ className, links, heading }: LinksBlockProps) => {
  return (
    <Box as="aside" sx={{ width: "400px" }} className={className}>
      <Heading as="h1" variant="subtitle">
        {heading}
      </Heading>
      {links &&
        links.map(({ url, text }, index) => (
          <AguilaButton text={text} url={url} variant="textOnly" key={index} />
        ))}
    </Box>
  )
}

export default LinksBlock
